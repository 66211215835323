/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect} from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon';
import { DoubleSide } from 'three';
import { useHover } from '../Hooks'

export default function Model({ ...props }) {
  const { nodes, materials } = useGLTF('/droppers/gmail.gltf')
  const red = useTexture('/matcaps/D04444_AF2F2F_8B2424_9B2C2C.png');
  const white = useTexture('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')
  // const [ group, api ] = useBox(()=>({mass: 1, args: [2.8,2.3,.4], ...props}))
  const setHover = useHover(false)

  // useEffect(()=>{
  //   api.angularVelocity.set(0,10,0)
  // }, [api])

  return (
    <group 
      // ref={group} 
      {...props} 
      dispose={null} 
      onPointerOver={() => setHover(true)} 
      onPointerOut={() => setHover(false)}
      onClick={(e)=>{ e.stopPropagation(); window.open('mailto:edwin.webb@googlemail.com', '_blank') }}
      >
      <group position={[0,0.2,0.05]}>
        <mesh
          geometry={nodes.Plane001.geometry}
          material={nodes.Plane001.material}
          position={[0, 0, 0.14]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1.4, 1.36, 1.36]}
          >
          <meshMatcapMaterial matcap={white} side={DoubleSide} />
        </mesh>
        <group position={[0, -0.07, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1.22, 1.22, 1.05]}>
          <mesh geometry={nodes.Plane_1.geometry} material={nodes.Plane_1.material}>
            <meshMatcapMaterial matcap={white} />
          </mesh>
          <mesh geometry={nodes.Plane_2.geometry} material={materials['Material.002']}>
            <meshMatcapMaterial matcap={red} />
          </mesh>
        </group>
      </group>
    </group>
  )
}

useTexture.preload('/matcaps/D04444_AF2F2F_8B2424_9B2C2C.png')
useTexture.preload('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')

useGLTF.preload('/droppers/gmail.gltf')
