import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useCylinder } from '@react-three/cannon'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/droppers/heart.gltf')
  const [ group, api ] = useCylinder(() => ({ mass: 1, args:[.6,.1,1,4], ...props }))
  const matcap = useTexture('/matcaps/ED5087_FAC3D0_1C0B0C_FC84CC.png')

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        onClick={()=>{api.applyImpulse([Math.random()*20,Math.random()*20,Math.random()*20], [0,0,0])}}
        castShadow
        receiveShadow
        geometry={nodes.heart_teamRed.geometry}
        material={materials['Red.015']}
        rotation={[Math.PI / 2, 0, 1]}
      >
        <meshMatcapMaterial matcap={matcap} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/droppers/heart.gltf')
useTexture.preload('/matcaps/ED5087_FAC3D0_1C0B0C_FC84CC.png')
