import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

export default function Model(props) {
  const [ group ] = useBox(()=>({mass:1, args: [.4,.6,.7], ...props}))
  const { nodes, materials } = useGLTF('/droppers/sushisalmon.gltf')
  const pink = useTexture('/matcaps/C7938D_E0DBE1_D1BBBC_D5CCCB.png')
  const rice = useTexture('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')
  const darkpink = useTexture('/matcaps/C7B9A1_F8F1E4_EEE4D2_E4D8C4.png')
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[4,4,4]} position={[0,-.3,0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sushiSalmon.geometry}
          material={materials._defaultMat}> 
          <meshMatcapMaterial matcap={rice} />
          <group position={[0, 0.07, 0]} scale={[0.85, 1.69, 1.15]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Mesh_salmon.geometry}
              material={materials.orange}
              >
              <meshMatcapMaterial matcap={pink} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Mesh_salmon_1.geometry}
              material={materials.brownLight}
              >
              <meshMatcapMaterial matcap={darkpink} />
            </mesh>
          </group>
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/droppers/sushisalmon.gltf')
useTexture.preload('/matcaps/C7938D_E0DBE1_D1BBBC_D5CCCB.png')
useTexture.preload('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')
useTexture.preload('/matcaps/C7B9A1_F8F1E4_EEE4D2_E4D8C4.png')