import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { useCylinder } from "@react-three/cannon";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/droppers/tree.gltf");
  const wood = useTexture('/matcaps/8D553D_3C2419_5D3727_AA766C.png')
  const foliage = useTexture('/matcaps/9CC338_4E671A_799F27_8CAC2C.png')
  const [ treeRef, api ] = useCylinder(() => ({ mass: 1, args:[0,1,3,12], ...props }))
  return (
    <group  ref={treeRef} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} position={[0,-1.75,0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder017.geometry}
        >
          <meshMatcapMaterial matcap={foliage} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder017_1.geometry}
          >
          <meshMatcapMaterial matcap={wood} />
        </mesh>
      </group>
    </group>
  );
}

useTexture.preload('/matcaps/8D553D_3C2419_5D3727_AA766C.png')
useTexture.preload('/matcaps/9CC338_4E671A_799F27_8CAC2C.png')

useGLTF.preload("/droppers/tree.gltf");