import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container)

console.log(`%c

███████ ██████  ██     ██ ██ ███    ██     ██     ██ ███████ ██████  ██████      ██████   ██████  ██████  ████████ ███████  ██████  ██      ██  ██████  
██      ██   ██ ██     ██ ██ ████   ██     ██     ██ ██      ██   ██ ██   ██     ██   ██ ██    ██ ██   ██    ██    ██      ██    ██ ██      ██ ██    ██ 
█████   ██   ██ ██  █  ██ ██ ██ ██  ██     ██  █  ██ █████   ██████  ██████      ██████  ██    ██ ██████     ██    █████   ██    ██ ██      ██ ██    ██ 
██      ██   ██ ██ ███ ██ ██ ██  ██ ██     ██ ███ ██ ██      ██   ██ ██   ██     ██      ██    ██ ██   ██    ██    ██      ██    ██ ██      ██ ██    ██ 
███████ ██████   ███ ███  ██ ██   ████      ███ ███  ███████ ██████  ██████      ██       ██████  ██   ██    ██    ██       ██████  ███████ ██  ██████  
                                                                                                                                                        
                                                                                                                                                        
`, 'background: #222; color: #bada55');

root.render(<App />);
