import { DepthOfField, EffectComposer, Noise, SMAA } from "@react-three/postprocessing";
import { BlendFunction } from 'postprocessing';

const Shaders = (props) => {
    return(<EffectComposer  multisampling={0}>
        <Noise
          premultiply // enables or disables noise premultiplication
          blendFunction={BlendFunction.OVERLAY} // blend mode
          opacity={.2}
        />
        <DepthOfField
          focusDistance={0} // where to focus
          focalLength={0.02} // focal length
          bokehScale={2} // bokeh size
        />
        <SMAA />
      </EffectComposer>)
}

export default Shaders