import React from "react";
import { useBox } from "@react-three/cannon";
import { Box, useTexture } from "@react-three/drei";
import { useHover } from "../Hooks";

function ClientCube(props) {
  const {
    textureURL,
    url
  } = props
  const defArgs = [1,1,1]
  const [ref] = useBox(()=>({mass:1, args:defArgs,...props}))
  const texture = useTexture('/clients/' + textureURL)
  const setHover = useHover(false)
  return(<group ref={ref}>
    <mesh 
      onClick={(e)=>{ e.stopPropagation(); window.open(url, '_blank') }}
      onPointerOver={() => setHover(true)} 
      onPointerOut={() => setHover(false)}
    >
      <boxBufferGeometry args={defArgs} />
      <meshBasicMaterial map={texture} color="white" />
    </mesh>
  </group>)
}

export default ClientCube