
import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { useCylinder } from "@react-three/cannon";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/droppers/gltflogo.gltf");
  const texture = useTexture('/matcaps/6EC66E_C9F8C9_A3E8A3_B4F1B4.png')
  const [ boxref ] = useCylinder(() => ({ mass: 1, args:[.6,.6,.1,12], ...props }))
  return (
    <group ref={boxref} {...props} dispose={null}>
      <group rotation={[0, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.defaultMaterial.geometry}
            material={nodes.defaultMaterial.material}
          >
            <meshMatcapMaterial matcap={texture} />
          </mesh>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/droppers/gltflogo.gltf");
useTexture.preload('/matcaps/6EC66E_C9F8C9_A3E8A3_B4F1B4.png')
