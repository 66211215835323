import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { DoubleSide } from 'three'
import { useCylinder } from '@react-three/cannon'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/droppers/umbrella.gltf')
  const matcap = useTexture('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')
  const [ group, api ] = useCylinder(() => ({ mass: 1, args:[1,0,1.5,12], ...props }))
  return (
    <group ref={group} {...props} dispose={null} 
    onClick={()=>{api.applyImpulse([Math.random()*20,Math.random()*20,Math.random()*20], [0,0,0])}}
    >
        <group rotation={[.9,0, 0]} position={[0,0,.6]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['CuteUmbrella_CrossPink_CuteUmbrella_Log556-GRP-378912-CrossPink'].geometry}
        material={materials.CrossPink_001}
        rotation={[0, 0, 0]}>
            <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['CuteUmbrella_White_CuteUmbrella_Log556-GRP-378912-White'].geometry}
          material={materials.White}
        >
            <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['CuteUmbrella_001_CuteUmbrella_Log556-GRP-378912-2'].geometry}
          material={materials['2.001']}
        >
            <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['CuteUmbrella_002_CuteUmbrella_Log556-GRP-378912-__'].geometry}
          material={materials.__}
        >
            <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes['CuteUmbrella_Material_CuteUmbrella_Log556-GRP-378912-Default_Ma'].geometry
          }
          material={materials.Default_Material}
        >
            <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
        </mesh>
        </group>
    </group>
  )
}

useGLTF.preload('/droppers/umbrella.gltf')
useTexture.preload('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')