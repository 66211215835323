// import { Debug, Physics } from "@react-three/cannon";
import { Loader, OrbitControls, Scroll, ScrollControls, Stats } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef } from "react";
import Stage from './CreativeStage'
import Dropper from "./Dropper";
import Sections from "./sections/Sections";
import pagesData from './pagesData'
import Shaders from "./Shaders";
import HTMLSections from './sections/HTMLSections'
import Stacked from './Stacked'
import { Debug, Physics } from "@react-three/cannon";
import SocialLinks from "./SocialLinks";

function App() {
  return(<>
    <Canvas>
      <fog attach="fog" args={['#FFFFFF', 0, 60]} />
      <color attach={'background'} args={[0xffffff]} />
      <ambientLight />
      <Suspense>
        <SocialLinks position={[0,5.5,1]} rotation={[0,0.09,0]} />
        <SocialLinks position={[0,-5.5,1]} rotation={[0,0.09,0]} />
        <Physics gravity={[0,-1,0]}>
          {/* <Debug color="black" scale={1}> */}
            <Dropper />
            <Stage />
          {/* </Debug> */}
        </Physics>
        <ScrollControls pages={pagesData.length + 1}>
          <Sections /> 
          <Stacked />
          <Scroll html>
            <HTMLSections />
          </Scroll>
        </ScrollControls>
      </Suspense>

      {/* <OrbitControls /> */}
      <Shaders />
    </Canvas>
    <Loader />
    {/* <Stats/> */}
  </>)
}

export default App;
