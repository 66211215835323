import React, { useRef } from 'react'
import { useGLTF,  useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

export default function Model(props) {
  const [ group ] = useBox(()=>({mass:1, args: [.4,.5,.6], ...props}))
  const { nodes, materials } = useGLTF('/droppers/sushiegg.gltf')
  const egg = useTexture('/matcaps/A27216_E9D036_D0AB24_DCB927.png')
  const band = useTexture('/matcaps/9CC338_4E671A_799F27_8CAC2C.png')
  const rice = useTexture('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')

  return (
    <group ref={group} {...props} dispose={null}>
        <group scale={[4,4,4]} position={[0,-.2,0]}>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_sushiEgg.geometry}
            material={materials._defaultMat}
        >
            <meshMatcapMaterial matcap={rice} />
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_sushiEgg_1.geometry}
            material={nodes.Mesh_sushiEgg_1.material}
            >
            <meshMatcapMaterial matcap={band} />
        </mesh>
        <group position={[0, 0.07, 0]}>
            <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_egg.geometry}
            material={materials.yellow}
            >
            <meshMatcapMaterial matcap={egg} />
            </mesh>
            <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_egg_1.geometry}
            material={nodes.Mesh_egg_1.material}
            >
            <meshMatcapMaterial matcap={band} />
            </mesh>
        </group>
        </group>
      
    </group>
  )
}

useGLTF.preload('/droppers/sushiegg.gltf')
useTexture.preload('/matcaps/A27216_E9D036_D0AB24_DCB927.png')
useTexture.preload('/matcaps/9CC338_4E671A_799F27_8CAC2C.png')
useTexture.preload('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')