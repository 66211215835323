
import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

export default function Model(props) {
  const { nodes } = useGLTF('/droppers/creative.gltf')
  const [ group, api ] = useBox(() => ({ mass: 1, args:[5.92,.66,.86], ...props }))
  const matcap = useTexture('/matcaps/80CA23_B7EE37_D5FA4C_A3E434.png')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={nodes.Text.material}
        position={[-5.92/2, 0, 0.86/2]}
        onClick={()=>{api.applyImpulse([Math.random()*2,Math.random()*20,Math.random()*2 - 1], [0,0,0])}}
      >
          <meshMatcapMaterial matcap={matcap} />
        </mesh>
    </group>
  )
}

useGLTF.preload('/droppers/creative.gltf')
useTexture.preload('/matcaps/80CA23_B7EE37_D5FA4C_A3E434.png')