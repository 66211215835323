import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon'
import { useEffect } from 'react'
import { useHover } from '../Hooks'

export default function Model({ ...props }) {
  const { nodes } = useGLTF('/droppers/twitterlogo.gltf')
  const blue = useTexture('/matcaps/0489C5_0DDDF9_04C3EE_04AFE1.png')
  //const [ group, api ] = useBox(()=>({mass: 1, args: [2.7*.8,2.5*.8,.25*.8], ...props}))
  const setHover = useHover(false)

  return (
    <group 
      // ref={group} 
      {...props} 
      dispose={null} 
      onPointerOver={() => setHover(true)} 
      onPointerOut={() => setHover(false)}
      onClick={(e)=>{ 
        e.stopPropagation(); 
        window.open('https://twitter.com/edwinwebb', '_blank') }
      }
      >
      <mesh
        geometry={nodes.twitter_logo.geometry}
        rotation={[-Math.PI, 0, 0]}
        scale={0.008}
      >
        <meshMatcapMaterial matcap={blue} />
      </mesh>
    </group>
  )
}

useTexture.preload('/matcaps/0489C5_0DDDF9_04C3EE_04AFE1.png')

useGLTF.preload('/droppers/twitterlogo.gltf')
