/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, PerspectiveCamera, useAnimations, useScroll, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { MeshMatcapMaterial } from "three";
import VideoPlane from "./sections/VideoPlane";
import pagesData from "./pagesData";

export default function Model(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF("/ewstacked.gltf");
  const { actions } = useAnimations(animations, group);
  const scrollData = useScroll()
  const letters = useRef()
  const matcap = useTexture('/matcaps/saturatedpink.png')
  const dataLength = pagesData.length;
  const materialTest = useMemo(()=> {
    return new MeshMatcapMaterial({matcap:matcap})
  }, [matcap])

  useEffect(() => void (actions['Action.001'].play().paused = true), [])

  useEffect(()=>{
    letters.current && letters.current.children.forEach( child => {
      child.material = materialTest
    })
  }, [letters, matcap])

  useFrame(state => {
    actions["Action.001"].time = actions["Action.001"].getClip().duration * scrollData.offset

    letters.current.children.forEach((child, index) => {
      const et = state.clock.elapsedTime
      child.rotation.x += Math.sin((et + index * 2000) / 3) / 10000
      child.rotation.y += Math.cos((et + index * 2000) / 2) / 10000
      child.rotation.z += Math.sin((et + index * 2000) / 3) / 10000
    })
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Spiral002"
          position={[0, 8.44, 0]}
          rotation={[0, -1.53, 0]}
        />
        <group
          name="Camera"
          position={[0.91, 8.38, 10.14]}
          rotation={[1.43, 0.01, -0.09]}
        >
          <PerspectiveCamera
            name="Camera_Orientation"
            makeDefault={true}
            far={1000}
            near={0.1}
            fov={22.9}
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <VideoPlane
          name="Plane"
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={nodes.Plane.material}
          position={[-1.25, 7.25, 2.15]}
          rotation={[-1.75, -0.02, 0.13]}
          scale={[1.08, 1, 1.92]}
          url="/videos/creative_vert.mp4"
          index={0}
          dataLength={dataLength}
          />
        <VideoPlane
          url="/videos/trilogiq3d.mp4"
          index={1}
          dataLength={dataLength}
          name="Plane001"
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={nodes.Plane001.material}
          position={[2.16, 5.47, -0.63]}
          rotation={[-Math.PI / 2, 0, 2.54818]}
          scale={[1.08, 1, 1.92]}
          />
        <VideoPlane
          url="/videos/london.mp4"
          index={2}
          dataLength={dataLength}
          name="Plane002"
          castShadow
          receiveShadow
          geometry={nodes.Plane002.geometry}
          material={nodes.Plane002.material}
          position={[-3.35, 2.18, -0.06]}
          rotation={[-Math.PI / 2, 0, -1.17]}
          scale={[1.08, 1, 1.92]}
          />
        <VideoPlane
          url="/videos/bigsites.mp4"
          index={3}
          dataLength={dataLength}
          name="Plane003"
          castShadow
          receiveShadow
          geometry={nodes.Plane003.geometry}
          material={nodes.Plane003.material}
          position={[2.1, -1.77, 1.93]}
          rotation={[-Math.PI / 2, 0, 1.5]}
          scale={[1.08, 1, 1.92]}
          />
        <VideoPlane
          url="/videos/tokyo.mp4"
          index={4}
          dataLength={dataLength}
          name="Plane004"
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={nodes.Plane004.material}
          position={[0.55, -5.28, -1.92]}
          rotation={[-1.52, 0, -2.33]}
          scale={[1.08, 1, 1.92]}
          />
        <group ref={letters}>
        <mesh
          name="E001"
          castShadow
          receiveShadow
          geometry={nodes.E001.geometry}
          material={nodes.E001.material}
          position={[-0.38, 6.57, -0.11]}
          rotation={[1.27, 0.35, -0.01]}
        />
        <mesh
          name="D"
          castShadow
          receiveShadow
          geometry={nodes.D.geometry}
          material={materialTest}
          position={[0.24, 5.59, -0.05]}
          rotation={[1.62, -0.19, -0.18]}
        />
        <mesh
          name="W001"
          castShadow
          receiveShadow
          geometry={nodes.W001.geometry}
          material={nodes.W001.material}
          position={[-0.16, 4.01, -0.25]}
          rotation={[1.87, 0, 0.13]}
        />
        <mesh
          name="I"
          castShadow
          receiveShadow
          geometry={nodes.I.geometry}
          material={nodes.I.material}
          position={[-0.06, 2.3, -0.08]}
          rotation={[1.44, -0.05, -2]}
        />
        <mesh
          name="N"
          castShadow
          receiveShadow
          geometry={nodes.N.geometry}
          material={nodes.N.material}
          position={[0.06, 0.69, -0.08]}
          rotation={[1.42, 0.07, 0.79]}
        />
        <mesh
          name="W"
          castShadow
          receiveShadow
          geometry={nodes.W.geometry}
          material={nodes.W.material}
          position={[0.08, -1.27, 0.04]}
          rotation={[1.52, 0.05, -0.85]}
        />
        <mesh
          name="E"
          castShadow
          receiveShadow
          geometry={nodes.E.geometry}
          material={nodes.E.material}
          position={[0.15, -3.08, 0.03]}
          rotation={[1.79, 0.33, -2.23]}
        />
        <mesh
          name="B1"
          castShadow
          receiveShadow
          geometry={nodes.B1.geometry}
          material={nodes.B1.material}
          position={[0.21, -4.8, 0.03]}
          rotation={[1.43, -0.09, 2.81]}
        />
        <mesh
          name="B2"
          castShadow
          receiveShadow
          geometry={nodes.B2.geometry}
          material={nodes.B2.material}
          position={[0.2, -6.61, 0.03]}
          rotation={[1.42, 0.16, 1.63]}
        />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/ewstacked.gltf");