import Creative from './droppers/Creative'
import Tech from './droppers/Tech'
import ReactLogo from './droppers/ReactLogo'
import Umbrealla from './droppers/Umbrella'
import Heart from './droppers/Heart'
import React, { useEffect, useMemo, useRef } from 'react'
import Threelogo from './droppers/Threelogo'
import SushiSalmon from './droppers/SushiSalmon'
import SushiEgg from './droppers/SushiEgg'
import useStore from "./store";
import ClientCube from './droppers/Client'
import GLTF from './droppers/GLTF'
import Camera from './droppers/Camera'
import Macbook from './droppers/Macbook'
import Tree from './droppers/Tree'
import { useTexture } from '@react-three/drei'

function Dropper(props) {
    const [items, dropLevel] = useStore(state => [state.items, state.dropLevel])
    const prevItemLength = useRef()
    const testBox = useRef()
    const allDroppers = useMemo(()=>{
      const dropMap = {
        'heart' : <Heart position={[6,2,6]} />,
        'creative' : <Creative rotation={[Math.PI/2,0.1,0]} position={[-2,8,-0.5]} />,
        'tech' : <Tech rotation={[Math.PI/2,-0.14,0]} position={[3,9,-0.5]} />,
        'react' : <ReactLogo position={[6,20,14]} rotation={[Math.PI/2,0,-Math.PI/2]} />,
        'umbrella' : <Umbrealla position={[6,20,26]} />,
        'three' : <Threelogo position={[6,2,6]} />,
        'tree' : <Tree position={[6,2,6]} />,
        'sushi1' : <SushiEgg />,
        'sushi2' : <SushiSalmon />,
        'gltf' : <GLTF position={[6,3,6]}  />,
        'camera' : <Camera  position={[6, 4,6]} />,
        'macbook' : <Macbook />
      }
      const clientMap = {
        'poke' : <ClientCube position={[6,5,6]} rotation={[1,1,1]} textureURL={'poke.jpg'} url='https://www.publicispoke.com/' />,
        'goodboy' : <ClientCube position={[6,7,6]} rotation={[2,2,2]} textureURL={'goodboy.png'} url="https://www.goodboydigital.com/" />,
        'akqa' : <ClientCube position={[6,9,6]} rotation={[3,1,1]} textureURL={'akqa-logo.png'} url="https://www.akqa.com/" />,
        'native' : <ClientCube position={[6,13,6]} rotation={[5,3,4]} textureURL={'native.gif'} url="https://native.com/" />,
        'iflyer' : <ClientCube position={[6, 15,6]} rotation={[1,2,3]} textureURL={'iflyer.png'} url='https://iflyer.tv/' />,
        'zustand' : <ClientCube position={[6, 18,6]} rotation={[1,2,3]} textureURL={'zustand.png'} url='https://github.com/pmndrs/zustand' />,
        'castrol' : <ClientCube position={[6, 18,6]} rotation={[1,2,3]} textureURL={'castrol.png'} url='https://www.castrol.com' />,
        'nre' : <ClientCube textureURL={'nre.png'} url='https://www.nationalrail.co.uk/' />,
        'bp' : <ClientCube textureURL={'bp-logo.jpg'} url='https://bp.com' />,
        'javascript' : <ClientCube textureURL='javascript.png' url='https://codesandbox.io/u/edwinwebb' />,
        'twitterbox' : <ClientCube textureURL='twitterlogo.png' url='https://twitter.com/edwinwebb' />
      }
      return {
        ...dropMap,
        ...clientMap
      }
    })
    const dropItems = items.map((value, index) => {
      // const xSpread = MathUtils.randFloatSpread(width)
      const r = 10 * Math.sqrt(Math.random())
      const theta = Math.random() * 2 * Math.PI
      const x = r * Math.cos(theta)
      const z = r * Math.sin(theta)
      const y = ((dropLevel * -17) + 7.5) + 4 + Math.random()

      if(value === 'creative' || value === 'tech') {
        return React.cloneElement(allDroppers[value], { key: `dropitem-${index}` })
      } else if(allDroppers.hasOwnProperty(value)) {
        return React.cloneElement(allDroppers[value], { position: [x, y, z], key: `dropitem-${index}`, rotation: [Math.random()*Math.PI,Math.random()*Math.PI,Math.random()*Math.PI] })
      } else {
        console.log(`missing dropper ${value}`)
      }
    })

    useEffect(()=>{
      prevItemLength.current = items.length
    })

    return(<group {...props}>
      { dropItems }
    </group>)
}

useTexture.preload('/clients/poke.jpg')
useTexture.preload('/clients/goodboy.png')
useTexture.preload('/clients/akqa-logo.png')
useTexture.preload('/clients/native.gif')
useTexture.preload('/clients/iflyer.png')
useTexture.preload('/clients/zustand.png')
useTexture.preload('/clients/castrol.png')
useTexture.preload('/clients/nre.png')
useTexture.preload('/clients/bp-logo.jpg')
useTexture.preload('/clients/javascript.png')

export default Dropper