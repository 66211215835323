import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

export default function Model({ ...props }) {
  const { nodes, materials } = useGLTF('/droppers/camera.gltf')
  const [ group ] = useBox(()=>({mass:1, args:[2,1.2,1.3], ...props}))
  const lens = useTexture('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')
  const body = useTexture('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -0.8, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.1}>
        <mesh geometry={nodes.Mesh.geometry}>
          <meshMatcapMaterial matcap={lens} />
        </mesh>
        <mesh geometry={nodes.Mesh_1.geometry} >
          <meshMatcapMaterial matcap={body} />
        </mesh>
        <mesh geometry={nodes.Mesh_2.geometry} >
          <meshMatcapMaterial matcap={lens} />
        </mesh>
      </group>
    </group>
  )
}

useTexture.preload('/matcaps/9F9F9F_E4E4E4_D4D4D4_CCCCCC.png')
useTexture.preload('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')
useGLTF.preload('/droppers/camera.gltf')
