import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { DoubleSide } from 'three'
import { useCylinder } from '@react-three/cannon'

export default function Model(props) {
  const [ group, api ] = useCylinder(() => ({ mass: 1, args:[1,1,.3,3], ...props }))
  const { nodes, materials } = useGLTF('/droppers/threejs-logo.gltf')
  const matcap = useTexture('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')

  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[30,30,30]} rotation={[0,1.6,0]} position={[0.2,0,0.16]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve.geometry}
        material={nodes.Curve.material}
        position={[0.01, 0, 0.04]}
        rotation={[0, Math.PI / 4, 0]}
      >
          <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve001.geometry}
        material={nodes.Curve001.material}
        position={[0.01, 0, 0.04]}
        rotation={[0, Math.PI / 4, 0]}
      >
          <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve002.geometry}
        material={nodes.Curve002.material}
        position={[0.01, 0, 0.04]}
        rotation={[0, Math.PI / 4, 0]}
      >
          <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve003.geometry}
        material={nodes.Curve003.material}
        position={[0.01, 0, 0.04]}
        rotation={[0, Math.PI / 4, 0]}
      >
          <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve005.geometry}
        material={nodes.Curve005.material}
        position={[0.01, 0, 0.04]}
        rotation={[0, Math.PI / 4, 0]}
      >
          <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve007.geometry}
        material={nodes.Curve007.material}
        position={[0.01, 0, 0.04]}
        rotation={[0, Math.PI / 4, 0]}
      >
          <meshMatcapMaterial matcap={matcap} side={DoubleSide} />
      </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/droppers/threejs-logo.gltf')
useTexture.preload('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')


