import Twitter from './droppers/Twitterlogo'
import Gmail from './droppers/Gmail'
import Codesandbox from './droppers/Codesandbox'
import Github from './droppers/Github'
import LinkedIn from './droppers/Linkedin'
import { useThree } from '@react-three/fiber'

const SocialLinks = (props) => {
    const {width} = useThree( s => s.viewport)
    const scalemod = width < 7 ? width < 5 ? .175 : .25 : .5
    return(<group {...props} scale={scalemod/2}>
        <LinkedIn position={[-8,0,0]} rotation={[Math.PI/2,0,0]} scale={[1,.5,1]} />
        <Twitter position={[-4,0,0]} mass={0} />
        <Gmail position={[0,0,0]} mass={0} />
        <Codesandbox position={[4,0,0]} rotation={[Math.PI/2,0,0]} mass={0} />
        <Github position={[8,0,0]} mass={0} rotation={[Math.PI/2,0,0]} />
    </group>)
}

export default SocialLinks