/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useState } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useCylinder } from '@react-three/cannon'
import { useHover } from '../Hooks'

export default function Model({ ...props }) {
  const { nodes } = useGLTF('/droppers/github.gltf')
  const black = useTexture('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')
  //const [group] = useCylinder(()=>({mass: 0, args:[1.15,1.15,.2,8], ...props}))
  const setHover = useHover(false)

  return (
    <group {...props} dispose={null}>
      <mesh 
        geometry={nodes.Curve.geometry} 
        scale={[8,8,8]} 
        onPointerOver={() => setHover(true)} 
        onPointerOut={() => setHover(false)}
        onClick={(e)=>{ 
          e.stopPropagation(); 
          window.open('https://github.com/edwinwebb', '_blank') }
        }
        >
        <meshMatcapMaterial matcap={black} />
      </mesh>
    </group>
  )
}

useTexture.preload('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')
useGLTF.preload('/droppers/github.gltf')
