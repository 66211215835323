import { useScroll } from "@react-three/drei"
import useStore from "../store";
import pagesData from '../pagesData'
import { useFrame, useThree } from "@react-three/fiber";
import { useState } from "react";
// import HomeSection from './HomeSection'
// import HeaderText from './HeaderText'
// import VideoPlane from "./VideoPlane";

// generated section
function AutoSection(props) {
  const { header, subheader, width, height, index, length, onVisible, video, vidPos, vidRot } = props
  const [hasDropped, setDropped]  = useState(false)
  // const [play, setPlay] = useState(false)
  const scrolldata = useScroll()
  // const baseFormat = {
  //   color: '#120e10',
  //   maxWidth: width * 0.8,
  //   textAlign: 'center', //index % 2 ? 'left' : 'right',
  //   // anchorX: 'center',
  //   anchorY: 'top',
  //   font: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@600'
  // }
  // const headerStyle = {
  //   ...baseFormat,
  //   fontSize: 1,
  //   letterSpacing: -0.05
  // }
  // const subStyle = {
  //   ...baseFormat,
  //   fontSize: .5,
  //   letterSpacing: -0.05
  // }
  // const planeWidth = width * 0.6;
  // const planeHeight = planeWidth * 0.5625

  useFrame(()=>{
    const e = scrolldata.visible(index / length, 1 / length, 0.05)
    if(e && !hasDropped) {
      setDropped(true)
      onVisible(scrolldata.offset)
    }
  })

  return(
    <group {...props}>
      {/* <Text {...headerStyle} position={[0,height/2,0]}>{header}</Text> */}
      {/* <HeaderText position={[0,height/2 - 1,0]} maxWidth={width * 0.8}>{subheader}</HeaderText> */}
      {/* {video && <VideoPlane position={vidPos} rotation={[vidRot[0],vidRot[1],vidRot[2]]} scale={[1.08, 1, 1.92]} width={planeWidth} height={planeHeight} url={video} play={play} />} */}
    </group>
  )
}

// Make the 100% sections to scroll past and hodl content
function Sections(props) {
  const { width, height } = useThree((state) => state.viewport)
  // const scrolldata = useScroll()
  const addItems = useStore( state => state.addItems ) // this will add dropper IDs to the store
  const setDropLevel = useStore( state => state.setDropLevel ) // this will add dropper IDs to the store
  // const scrollRef = useRef() // useScroll offset target
  // auto generated pages
  const pagesDataMap = pagesData.map( (data, i) => (
    <AutoSection 
      header={data.header} 
      subheader={data.subheader} 
      vidPos={data.position}
      vidRot={data.rotation}
      key={data.header} 
      width={width}
      height={height}
      index={i}
      length={pagesData.length}
      video={data.video}
      onVisible={(offset)=>{
        addItems(data.droppers)
        setDropLevel(offset)
      }}
      />
  ))

  //const pagesBlock = pagesData.map((d, i)=>(<Plane args={[width, height]} position-y={(i+1) * -height}><meshBasicMaterial color={Math.random() * 0xffffff} /></Plane>))

  // useFrame(() => {
  //   const offset = scrolldata.offset
  //   scrollRef.current.position.y = offset * height * pagesData.length
  // })

  return(<group {...props}>
    {/* <group ref={scrollRef}> */}
      {pagesDataMap}
    {/* </group> */}
  </group>)
}

export default Sections