import { Plane, useScroll } from "@react-three/drei"
import { useFrame } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { sRGBEncoding, DoubleSide } from "three";

const VideoPlane = (props) => {
  const { url, index, dataLength } = props
  const [videoTexture] = useState(() => Object.assign(document.createElement('video'), { src: `${url}`, crossOrigin: 'Anonymous', loop: true, autoplay: 'true', muted: 'true' }))
  const [ play, setPlay ] = useState(false)
  const scrolldata = useScroll()

  useEffect(()=>{
    if(play) { 
      videoTexture.play() 
    } else {
      videoTexture.pause()
    }
  }, [play])

  useFrame(()=>{
    const e = scrolldata.visible(index / dataLength, index / dataLength, 0.05)
    if(e && !play) {
      setPlay(true)
    }
    if(!e && play) {
      setPlay(false)
    }
  })

  return(<mesh {...props}>
    <meshBasicMaterial side={ DoubleSide }>
      <videoTexture attach="map" args={[videoTexture]} encoding={sRGBEncoding} />
    </meshBasicMaterial>
  </mesh>)
}

export default VideoPlane