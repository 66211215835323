
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { useHover } from "../Hooks";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/droppers/linkedin.gltf");
  const matcap = useTexture('matcaps/36C8FA_176ACB_24A7EF_1D93EC.png')

  const setHover = useHover(false)

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve.geometry}
        material={materials["SVGMat.001"]}
        position={[-1.02, 0, 1.02]}
        scale={100}
        onPointerOver={() => setHover(true)} 
        onPointerOut={() => setHover(false)}
        onClick={(e)=>{ 
            e.stopPropagation(); 
            window.open('https://www.linkedin.com/in/edwinwebb/', '_blank') }
          }
      >
          <meshMatcapMaterial matcap={matcap}  />
    </mesh>
    </group>
  );
}

useGLTF.preload("/droppers/linkedin.gltf");
useTexture.preload('matcaps/36C8FA_176ACB_24A7EF_1D93EC.png')