import React, { useRef } from "react";
import { usePlane } from '@react-three/cannon'
import Creative from './droppers/Creative'
import Tech from './droppers/Tech'

export default function Model(props) {
  const group = useRef();

  usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0], args:[100,100], position: [0,-9,0]}))

  return (
    <group ref={group} {...props} dispose={null}>
      <Creative rotation={[Math.PI/2,0.1,0]} position={[-2,-7,-1]} mass={1} />
      <Tech rotation={[Math.PI/2,-0.14,0]} position={[3,-7,-1]} mass={1} />
    </group>
  );
  }