import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useCylinder} from '@react-three/cannon'

export default function Model(props) {
  const { nodes } = useGLTF('/droppers/reactlogo.gltf')
  const [ group, api ] = useCylinder(() => ({ mass: 1, args:[1,1,.3,12], ...props }))
  const matcap = useTexture('/matcaps/5ECBA4_BCFAE7_92EED0_A1F4DC.png')

  return (
    <group ref={group} {...props} dispose={null} scale={[.2,.2,.2]} onClick={()=>{api.applyImpulse([Math.random()*20,Math.random()*20,Math.random()*20], [0,0,0])}}>
      <group rotation={[-Math.PI/2, Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={nodes.Cube.material}
          position={[0, 0, 0]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 1, 1]}>
          <meshMatcapMaterial matcap={matcap} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Torus001.geometry}
          material={nodes.Torus001.material}
          position={[0.34, -0.08, 0.02]}
          rotation={[-0.16, 0, -Math.PI / 2]}
          scale={[3.75, 1.47, 3]}>
          <meshMatcapMaterial matcap={matcap} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Torus002.geometry}
          material={nodes.Torus002.material}
          position={[-0.52, -0.1, 0.16]}
          rotation={[-1.18, 0, -Math.PI / 2]}
          scale={[3.75, 1.47, 3]}>
          <meshMatcapMaterial matcap={matcap} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Torus003.geometry}
          material={nodes.Torus003.material}
          position={[-0.04, -0.11, 0]}
          rotation={[0.89, 0, -Math.PI / 2]}
          scale={[3.75, 1.47, 3]}>
          <meshMatcapMaterial matcap={matcap} />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/droppers/reactlogo.gltf')
useTexture.preload('/matcaps/5ECBA4_BCFAE7_92EED0_A1F4DC.png')
