import pagesData from '../pagesData'

const Section = (props) => {
  const { index, data } = props
  const { header, subheader } = data

  return(<div 
    style={{ top: `${index * 100}vh` }}
    className="w-screen h-screen">
    <div className="md:container lg:max-w-5xl mx-auto flex flex-col items-end h-full justify-center">
      <div className="rounded-md p-8 backdrop-blur-sm backdrop-saturate-150 bg-white/30 md:w-96 md:text-right">
        <h2 className={'text-4xl pb-2 uppercase font-semibold tracking-tight font-open-sans'}>{header}</h2>
        <h3 className={'text-lg pb-4 font-open-sans'}>{subheader}</h3>
      </div>
    </div>
  </div>)
}

const Sections = (props) => {
  const pagesDataMap = pagesData.map( (data, i) => (
    <Section 
      key={`section${i}`} 
      index={i}
      data={data}
    />
  ))
  return(<div>{pagesDataMap}</div>)
}

export default Sections