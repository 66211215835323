import React, { useRef } from 'react'
import { useGLTF, useMatcapTexture, useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

export default function Model(props) {
  const { nodes } = useGLTF('/droppers/tech.gltf')
  const [ group, api ] = useBox(() => ({ mass: 1, args:[1.5*2,.66,.43*2], ...props }))
  const matcap = useTexture('/matcaps/80CA23_B7EE37_D5FA4C_A3E434.png')

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={nodes.Text.material}
        position={[-1.51, 0, 0.43]}
        onClick={()=>{api.applyImpulse([Math.random()*20,Math.random()*20,Math.random()*20], [0,0,0])}}
        >
            <meshMatcapMaterial matcap={matcap} />
          </mesh>
    </group>
  )
}

useGLTF.preload('/droppers/tech.gltf')
useTexture.preload('/matcaps/80CA23_B7EE37_D5FA4C_A3E434.png')
