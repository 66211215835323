/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useHover } from '../Hooks'
import { useCylinder } from '@react-three/cannon'

export default function Model({ ...props }) {
  const { nodes, materials } = useGLTF('/droppers/codesandbox.gltf')
  const black = useTexture('/matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')
  const setHover = useHover(false)
  // const [group] = useCylinder(()=>({mass: 1, args:[1.15,1.15,.2,6], ...props}))

  return (
    <group 
    // ref={group}
     {...props} 
     dispose={null}>
      <mesh 
        geometry={nodes.Curve.geometry} 
        scale={[8,8,8]} 
        onPointerOver={() => setHover(true)} 
        onPointerOut={() => setHover(false)}
        onClick={(e)=>{ 
          e.stopPropagation(); 
          window.open('https://codesandbox.io/u/edwinwebb', '_blank') }
        }
        >
        <meshMatcapMaterial matcap={black} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/droppers/codesandbox.gltf')
