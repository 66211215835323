import create from 'zustand'

const useStore = create(set => ({
  items: [],
  video: 'intro',
  index: 0,
  camerapos: [18, 4, 15],
  cameralook: [0, 0.42415539624972015, 0, 0.9055894212236817],
  cameradefault: true,
  dropLevel: 0,
  setDropLevel: dropLevel => set(state => ({dropLevel})),
  addItems: (newitems) => set(state => ({ items: [...state.items, ...newitems] })),
  setHeaders: (index, video) => set(state => ({index, video})),
  setCamera: (camerapos, cameralook) => set(state => ({camerapos, cameralook, cameradefault: false})),
  resetCamera: () => set( state => ({camerapos: [18, 4, 15], cameralook: [0, 0.42415539624972015, 0, 0.9055894212236817], cameradefault: true}) ),
  reset: () => set({ items: [] })
}))

export default useStore