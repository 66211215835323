import { useEffect, useState} from "react"

export function useHover() {
  const [ isHover, setHover ] = useState(false)
  useEffect(()=>{
    document.body.style.cursor = isHover ? 'pointer' : 'auto'
  }, [isHover])

  return setHover
}
