import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

export default function Model({ ...props }) {
  const [ group ] = useBox(()=>({mass:1, args:[3,.1,2], ...props}))
  const { nodes} = useGLTF('/droppers/macbook.gltf')
  const sliver = useTexture('/matcaps/8D8D8D_DDDDDD_CCCCCC_B7B7B7.png')
  const screen = useTexture('matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')
  const bevel = useTexture('matcaps/0F0F0F_4B4B4B_1C1C1C_2C2C2C.png')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, .05, 1]} scale={[0.1, 0.1, 0.1]}>
        <group  scale={5.8}>
          <mesh geometry={nodes.Circle002.geometry} material={nodes.Circle002.material}>
            <meshMatcapMaterial matcap={sliver} />
          </mesh>
          <mesh geometry={nodes.Circle002_1.geometry} material={nodes.Circle002_1.material}>
            <meshMatcapMaterial matcap={bevel} />
          </mesh>
          <mesh geometry={nodes.Circle002_2.geometry} material={nodes.Circle002_2.material}>
            <meshMatcapMaterial matcap={bevel} />
          </mesh> 
          <mesh geometry={nodes.Circle002_3.geometry} material={nodes.Circle002_3.material}>
            <meshMatcapMaterial matcap={bevel} />
          </mesh> 
          <mesh geometry={nodes.Circle002_4.geometry} material={nodes.Circle002_4.material}>
            <meshMatcapMaterial matcap={screen} />
          </mesh> 
        </group>
      </group>
    </group>
  )
}
useTexture.preload('/matcaps/8D8D8D_DDDDDD_CCCCCC_B7B7B7.png')
useTexture.preload('matcaps/2A2A2A_DBDBDB_6A6A6A_949494.png')
useTexture.preload('matcaps/0F0F0F_4B4B4B_1C1C1C_2C2C2C.png')
useGLTF.preload('/droppers/macbook.gltf')
