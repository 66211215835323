const pagesData = [
  {
    header: 'Creative Developer',
    subheader: 'I make cutting edge websites and push what\'s possible in the browser. I code with Javascript, React, and Three.js. I\'m looking for work in the SF Bay Area.',
    droppers: [],
  },{
    header: 'Latest Work',
    subheader: 'My leadership and expertise helped set the direction of this international e-commerce project. Helping the team develop React and Three.js we built internal apps and an industry leading ecommerce site.',
    droppers: ['javascript', 'three', 'gltf', 'react','javascript', 'three', 'gltf', 'react']
  },{
    header: 'Design Prototypes',
    subheader: 'My breadth of experience and knowledge lets me understand ideas, business and user needs. My technical skills allow me to make tangible user experiences to innovate, inspire and communicate to all levels of the business. ',
    droppers: ['camera', 'heart', 'heart', 'tree','macbook','heart', 'tree','heart','macbook', 'camera']
  },{
    header: 'In London',
    subheader: 'I built engaging and memorable experiences with the best and brightest in London\'s demanding digital agencies. I helped my teams overcome challenging briefs and high pressure situations with hard work, intelligent contributions and cutting edge code.',
    droppers: ['nre','bp','castrol','nre','poke', 'akqa', 'goodboy', 'poke', 'akqa', 'goodboy','bp','castrol','nre']
  },{
    header: 'In Tokyo',
    subheader: 'I helped launch a startup in Tokyo as a front end developer, UX, and graphic designer. It\'s now one of Japan\'s biggest websites. I also worked as a print designer, photographer and Flash developer.',
    droppers: ['sushi1', 'sushi2','sushi1', 'sushi2', 'sushi1', 'sushi2']
  }
]

export default pagesData